import { http } from './config'
import { httpNoLoading } from './configNoLoading'

export default {
    getListaPremiacoes: (data) => {
        return httpNoLoading.get('financeiro/lista-premiacoes', data)
    },
    getListPremiacoesPagas: (data) => {
        return httpNoLoading.get('financeiro/lista-premiacoes-pagas', data)
    },
    saveSolicitaPagamento: (dado) => {
        const options = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        
        return http.post('financeiro/solicita-pagamento/', dado, options)
    }
}
