<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-list"></i>
      Premiações
    </div>
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <select
              name="paginas"
              id="paginas"
              class="custom-select"
              v-model="quantidadePage"
            >
              <option value="10" selected>Mostrar 10 itens</option>
              <option value="20">Mostrar 20 itens</option>
              <option value="30">Mostrar 30 itens</option>
              <option value="40">Mostrar 40 itens</option>
              <option value="50">Mostrar 50 itens</option>
            </select>
          </div>
          <div class="col-md-6">
            <div class="input-group mb-2">
              <input
                type="text"
                class="form-control"
                id="inlineFormInputGroup"
                v-model="pesquisaElemento"
                v-on:keyup.enter="pesquisa"
              />
              <i
                style="display: none"
                @click="limparFiltro"
                class="fa fa-times fa-lg icone-x"
                id="icone-x"
              ></i>
              <div class="input-group-prepend">
                <button class="btn btn-primary" v-on:click="pesquisa">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 5%">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#receber"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                >Premiações a receber</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                href="#recebida"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >Premiações recebidas</a
              >
            </li>
            <div style="margin-left: 56%">
              <button
                class="btn btn-primary"
                v-if="!disabled"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
              >
                Solicitar pagamento
              </button>
            </div>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="receber"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <table
                class="table table-striped table-bordered table-hover tabelaPremiacao"
              >
                <thead>
                  <tr>
                    <th @click="sort('cd_filial')">
                      Filial
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('dt_emissao')">
                      Data emissão
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('documento')">
                      Documento
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('cliente')">
                      Cliente
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('dt_vencimento')">
                      Data vencimento
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('vl_parcela')">
                      Valor parcela
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('status_parcela')">
                      Parcela paga
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('vl_premiacao')">
                      Premiação
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click="sort('previsao_pg')">
                      Previsão pagto.
                      <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(l, index) in sortedColumns"
                    :key="index"
                    v-if="!estaCarregandoPremio"
                  >
                    <td>
                      <span v-if="l.cd_filial == 1" class="badge badge-mga"
                        >Maringá</span
                      >
                      <span v-if="l.cd_filial == 2" class="badge badge-itj"
                        >Itajaí</span
                      >
                    </td>
                    <td class="text-center">{{ l.dt_emissao }}</td>
                    <td class="text-center">{{ l.documento }}</td>
                    <td data-toggle="tooltip" :title="l.cliente">
                      {{ l.cliente.substr(0, 23) }}
                      <span v-if="l.cliente.length > 23">...</span>
                    </td>
                    <td class="text-center">{{ l.dt_vencimento }}</td>
                    <td class="moeda-real text-right">
                      {{ l.vl_parcela | money }}
                    </td>
                    <td class="text-center">
                      <span v-if="l.status_parcela == 'RE'" class="parcelaSim"
                        >Sim</span
                      >
                      <span v-else class="parcelaNao">Não</span>
                    </td>
                    <td class="moeda-real text-right">
                      {{ l.vl_premiacao | money }}
                    </td>
                    <td class="text-center">
                      <span v-if="l.previsao_pg == '01/01/2000'"></span
                      ><span v-else>{{ l.previsao_pg }}</span>
                    </td>
                  </tr>
                  <tr v-if="estaCarregandoPremio">
                    <td colspan="9" class="text-center">
                      <b-spinner
                        variant="secondary"
                        class="mt-3 mb-3"
                        label="Buscando lista de premiações à receber..."
                      ></b-spinner>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-row v-if="this.listaAreceber.length > 0">
                <b-col>
                  Pagina {{ this.currentPageReceber }} de
                  {{ this.totalPagesReceber }}, resultado
                  {{ this.quantidadeResultsReceber }} de
                  {{ this.totalResultsReceber }}
                </b-col>
                <b-col>
                  <b-pagination
                    v-model="currentPageReceber"
                    :total-rows="this.totalResultsReceber"
                    :per-page="this.quantidadePage"
                    class="mt-4"
                  >
                    <template v-slot:first-text>
                      <span class="text-primary">
                        <i class="fa fa-step-backward"></i>
                      </span>
                    </template>
                    <template v-slot:prev-text @click="prevPage">
                      <span class="text-primary">
                        <i class="fa fa-chevron-left"></i>
                      </span>
                    </template>
                    <template v-slot:next-text @click="nextPage">
                      <span class="text-primary">
                        <i class="fa fa-chevron-right"></i>
                      </span>
                    </template>
                    <template v-slot:last-text>
                      <span class="text-primary">
                        <i class="fa fa-step-forward"></i>
                      </span>
                    </template>
                    <template v-slot:ellipsis-text>
                      <span>
                        <i class="fa fa-ellipsis-h"></i>
                      </span>
                    </template>
                    <template
                      v-slot:currentPageReceber="{ currentPageReceber, active }"
                    >
                      <b v-if="active">{{ currentPageReceber }}</b>
                      <i v-else>{{ currentPageReceber }}</i>
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
              <b-row v-else style="display: flex; justify-content: center">
                <strong>{{ listaVaziaMsg }}</strong>
              </b-row>
            </div>
            <div
              class="modal fade bd-example-modal-lg"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Solicitar pagamento de premiação
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Fechar"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body pb-0">
                    <table
                      class="table table-striped table-bordered table-hover tabelaPremiacao"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col">Documento</th>
                          <th scope="col">Cliente</th>
                          <th scope="col">Premiação</th>
                          <th scope="col">
                            <input
                              type="checkbox"
                              v-model="seleckAll"
                              @click="checkAll"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(lp, index) in listaPagar"
                          :key="index"
                          v-if="!estaCarregandoPagamentoPremio"
                        >
                          <td class="text-center">{{ lp.documento }}</td>
                          <td>{{ lp.cliente }}</td>
                          <td class="moeda-real text-right">
                            {{ lp.premiacao | money }}
                          </td>
                          <td class="text-center">
                            <input
                              type="checkbox"
                              v-model="solicitaPg"
                              :value="lp.id"
                              @change="updateCheckall"
                            />
                          </td>
                        </tr>
                        <tr v-if="estaCarregandoPagamentoPremio">
                          <td colspan="4" class="text-center">
                            <b-spinner
                              variant="secondary"
                              class="mt-3 mb-3"
                              label="Buscando lista de premiações para solicitar pagamento..."
                            ></b-spinner>
                          </td>
                        </tr>
                        <tr
                          v-if="
                            !estaCarregandoPagamentoPremio &&
                            listaPagar.length == 0
                          "
                        >
                          <td colspan="4" class="text-center">
                            Nenhuma pagamento para solicitar
                          </td>
                        </tr>
                        <tr
                          v-if="
                            !estaCarregandoPagamentoPremio &&
                            listaPagar.length > 0
                          "
                        >
                          <td
                            colspan="3"
                            class="totalPremio"
                            @change="updateCheckall"
                          >
                            Total: R$
                            <span class="moeda-real text-right">{{
                              totalPremioParaPagar | money
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="row">
                      <div class="form-group col-lg-3 col-md-3">
                        <label>Nota fiscal de serviço</label>
                        <input
                          type="number"
                          class="form-control"
                          v-model="nrNotaServico"
                        />
                      </div>
                      <div class="form-group col-lg-9 col-md-9">
                        <label>Anexar NFSe</label>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="arquivoNfseId"
                            name="arquivoNfseId"
                            ref="files"
                            accept=".pdf"
                            @change="arquivoNota($event)"
                          />
                          <label class="custom-file-label" for="customFile"
                            ><span v-if="nomeArquivo == ''"
                              >Escolher arquivo</span
                            ><span v-else>{{ nomeArquivo }}</span></label
                          >
                        </div>
                        <small class="form-text text-muted"
                          >*São suportados somente arquivos em formato
                          .pdf.</small
                        >
                      </div>
                    </div>
                    <div class="row borda-top-cinza lh-1 py-7px">
                      <div class="col-md-12">
                        <label for="">Modelos de NFS-e:</label>
                        <div class="modelo-nfse">
                          <a
                            class="btn btn-modelos-nfse mr-10px 11"
                            v-for="(modelo, indice) in modelosNfse"
                            :key="indice"
                            :href="modelo.img"
                            target="_blank"
                          >
                            <i
                              class="fa fa-file-pdf-o mr-5px"
                              aria-hidden="true"
                            ></i>
                            {{ modelo.titulo }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <small class="form-text text-muted" id="observacaoRodape"
                      >*A previsão de pagamento será gerada após a validação das
                      informações.</small
                    >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="solicitacaoPagamento"
                      :disabled="habilita"
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <b-modal
              id="modal-solicitacao-concluida"
              size="md"
              :hide-header="true"
              :hide-footer="true"
              :no-close-on-backdrop="true"
              centered
            >
              <template>
                <div>
                  <div class="modal-solicitacao-concluida-header">
                    <button
                      type="button"
                      class="btn btn-default btn-close"
                      aria-label="Close"
                      @click="fecharModal('modal-solicitacao-concluida')"
                    >
                      &#x2715;
                    </button>
                  </div>
                  <div class="modal-solicitacao-concluida-body">
                    <h4 id="titulo-modal-concluido">
                      <img
                        src="../../../assets/img/check-circle-blue.svg"
                        alt="Ícone ilustrativo do título: solicitação enviada com sucesso"
                        id="icone-check"
                      />
                      <span>Solicitação enviada com sucesso!</span>
                    </h4>
                    <p>
                      As informações enviadas serão analisadas. Após o
                      processamento, a previsão de pagamento ficará disponível
                      no menu "Premiações", coluna "Previsão de pagamento". Se
                      surgir alguma dúvida, clique aqui para entrar em contato
                      com o nosso departamento financeiro.
                    </p>
                    <a
                      :href="retornarLinkWhatsapp()"
                      target="_blank"
                      id="link-whatsapp-financeiro"
                      v-if="whatsapp"
                    >
                      <img
                        src="../../../assets/img/botao-whatsapp-2.png"
                        alt="Ícone do telefone whatsapp do financeiro"
                      />
                      Fale com Financeiro
                    </a>
                  </div>
                  <div class="modal-solicitacao-concluida-footer">
                    <button
                      type="button"
                      class="btn"
                      id="btn-ok"
                      @click="fecharModal('modal-solicitacao-concluida')"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </template>
            </b-modal>
            <div
              class="tab-pane fade"
              id="recebida"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div
                class="tab-pane fade show active"
                id="receber"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <table
                  class="table table-striped table-bordered table-hover tabelaPremiacao"
                >
                  <thead>
                    <tr>
                      <th scope="col" @click="sortPg('cd_filial')">
                        Filial
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('dt_emissao')">
                        Data emissão
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('documento')">
                        Documento
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('cliente')">
                        Cliente
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('dt_solicita_pg')">
                        Data solicitação
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('vl_parcela')">
                        Valor parcela
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('vl_premiacao')">
                        Premiação
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('nr_nfs')">
                        NFSe
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                      <th scope="col" @click="sortPg('dt_pagamento')">
                        Data pagto.
                        <i class="fa fa-sort" aria-hidden="true"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(lr, index) in sortedColumnsPagas" :key="index">
                      <td>
                        <span v-if="lr.cd_filial == 1" class="badge badge-mga"
                          >Maringá</span
                        >
                        <span v-if="lr.cd_filial == 2" class="badge badge-itj"
                          >Itajaí</span
                        >
                      </td>
                      <td class="text-center">{{ lr.dt_emissao }}</td>
                      <td class="text-center">{{ lr.documento }}</td>
                      <td>
                        {{ lr.cliente.substr(0, 23) }}
                        <span v-if="lr.cliente.length > 23">...</span>
                      </td>
                      <td class="text-center">{{ lr.dt_solicita_pg }}</td>
                      <td class="moeda-real text-right">
                        {{ lr.vl_parcela | money }}
                      </td>
                      <td class="moeda-real text-right">
                        {{ lr.vl_premiacao | money }}
                      </td>
                      <td class="text-center">{{ lr.nr_nfs }}</td>
                      <td class="text-center">{{ lr.dt_pagamento }}</td>
                    </tr>
                  </tbody>
                </table>
                <b-row v-if="this.listaRecebido.length > 0">
                  <b-col>
                    Pagina {{ this.currentPageRecebido }} de
                    {{ this.totalPagesRecebido }}, resultado
                    {{ this.quantidadeResultsRecebido }} de
                    {{ this.totalResultsRecebido }}
                  </b-col>
                  <b-col>
                    <b-pagination
                      v-model="currentPageRecebido"
                      :total-rows="this.totalResultsRecebido"
                      :per-page="this.quantidadePage"
                      class="mt-4"
                    >
                      <template v-slot:first-text>
                        <span class="text-primary">
                          <i class="fa fa-step-backward"></i>
                        </span>
                      </template>
                      <template v-slot:prev-text @click="prevPage">
                        <span class="text-primary">
                          <i class="fa fa-chevron-left"></i>
                        </span>
                      </template>
                      <template v-slot:next-text @click="nextPage">
                        <span class="text-primary">
                          <i class="fa fa-chevron-right"></i>
                        </span>
                      </template>
                      <template v-slot:last-text>
                        <span class="text-primary">
                          <i class="fa fa-step-forward"></i>
                        </span>
                      </template>
                      <template v-slot:ellipsis-text>
                        <span>
                          <i class="fa fa-ellipsis-h"></i>
                        </span>
                      </template>
                      <template
                        v-slot:currentPageRecebido="{
                          currentPageRecebido,
                          active,
                        }"
                      >
                        <b v-if="active">{{ currentPageRecebido }}</b>
                        <i v-else>{{ currentPageRecebido }}</i>
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
                <b-row v-else style="display: flex; justify-content: center">
                  <strong>{{ listaVaziaMsg }}</strong>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mr-5px {
  margin-right: 5px;
}

.mr-10px {
  margin-right: 10px;
}

.pb-10px {
  padding-bottom: 10px;
}

.py-7px {
  padding-top: 7px;
  padding-bottom: 7px;
}

.lh-1 {
  line-height: 1;
}

.badge-itj {
  color: #fff;
  background-color: #ff6d13;
  width: 39px;
}

.badge-mga {
  color: #fff;
  background-color: #0075eb;
}

.parcelaSim {
  color: green;
}

.parcelaNao {
  color: red;
}

.tabelaPremiacao {
  font-size: 12px;
}

#observacaoRodape {
  margin-right: 30%;
  color: blue !important;
}

td.totalPremio {
  text-align: right;
  font-weight: bold;
}

.alinhamentoDireito {
  position: absolute;
  left: 69.6%;
  /* right: 0em; */
}

.reticencias {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.icone-x {
  position: absolute;
  margin-top: 0.5em;
  margin-left: 25.5em;
  z-index: 10;
  cursor: pointer;
}

.modelo-nfse {
  width: 100%;
}

.btn-modelos-nfse {
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  padding: 4px 7px;
  line-height: 1;
}

.btn-modelos-nfse:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: #fff;
}

.borda-top-cinza {
  border-top: 1px solid #c8ced3;
}

#modal-solicitacao-concluida___BV_modal_outer_ {
  z-index: 1055 !important;
}

#modal-solicitacao-concluida .modal-solicitacao-concluida-header {
  border-bottom: none;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#modal-solicitacao-concluida .modal-solicitacao-concluida-footer {
  border-top: none;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#modal-solicitacao-concluida .btn-close {
  padding: 0;
  font-size: 1.3rem;
}

#modal-solicitacao-concluida h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

#icone-check {
  margin-right: 0.5em;
}

#modal-solicitacao-concluida h4 span {
  text-align: center;
}

#link-whatsapp-financeiro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2.5em;
  margin-bottom: 1em;
}

#link-whatsapp-financeiro img {
  width: 1.2em;
  margin-right: 0.5em;
}

#btn-ok {
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-size: 16px;
  padding: 0.3em 3em;
}

#btn-ok:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  color: #fff;
}

@media screen and (max-width: 1420px) {
  .icone-x {
    margin-left: 25em;
  }
}

@media screen and (max-width: 1400px) {
  .icone-x {
    margin-left: 24.5em;
  }
}

@media screen and (max-width: 1380px) {
  .icone-x {
    margin-left: 24em;
  }
}

@media screen and (max-width: 1365px) {
  .icone-x {
    margin-left: 23.5em;
  }
}

@media screen and (max-width: 1280px) {
  .icone-x {
    margin-left: 21em;
  }
}

@media screen and (max-width: 1024px) {
  .icone-x {
    margin-left: 14.5em;
  }
}
</style>

<script>
import MyDataTable from "@/components/ui/MyDataTable";
import Premiacao from "../../../services/premiacoes";
import Revenda from "../../../services/revenda";
import GrupoPermissao from "../../../services/grupoPermissao";
import "bootstrap/dist/js/bootstrap.min.js";

export default {
  name: "Clientes",
  components: { MyDataTable },
  data() {
    return {
      fields: ["documento", "cliente", "premiação", "check"],
      listaAreceber: [],
      fullListAreceber: [],
      listaPagar: [],
      fullListRecebido: [],
      listaRecebido: [],
      solicitaPg: [],
      valorSolicitado: [],
      listaRecebe: [],
      listaPago: [],
      nrNotaServico: "",
      arquivoNfse: "",
      pesquisaElemento: "",
      nomeArquivo: "",
      check: false,
      seleckAll: false,
      habilita: true,
      limpar: false,
      currentSort: "dt_emissao",
      currentSortDir: "asc",
      currentSortPg: "dt_emissao",
      currentSortDirPg: "asc",
      page: 1,
      pageSizeReceber: 10,
      pageSizeRecebido: 10,
      currentPageReceber: 1,
      currentPageRecebido: 1,
      quantidadePage: 10,
      totalPagesReceber: 0,
      totalPagesRecebido: 0,
      totalResultsReceber: 0,
      totalResultsRecebido: 0,
      quantidadeResultsReceber: 0,
      quantidadeResultsRecebido: 0,
      totalPremioParaPagar: 0,
      listaVaziaMsg: "Buscando informações",
      modelosNfse: [
        {
          titulo: "Optante Simples",
          img: process.env.VUE_APP_LOCAL + "assets/arquivos/modelo-simples.pdf",
        },
        {
          titulo: "Optante SIMEI",
          img: process.env.VUE_APP_LOCAL + "assets/arquivos/modelo-simei.pdf",
        },
        {
          titulo: "Normal",
          img:
            process.env.VUE_APP_LOCAL +
            "assets/arquivos/modelo-regime-normal.pdf",
        },
      ],
      whatsapp: "",
      estaCarregandoPremio: false,
      estaCarregandoPagamentoPremio: false,
      disabled: false,
    };
  },
  beforeMount() {
    this.loadPremium();
    this.loadPayment();
  },
  mounted() {
    this.validaPermissao();
    this.buscarWhatsappFinanceiro();
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/financeiro/premiacoes").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    loadPremium(data = null) {
      this.listaAreceber = [];
      this.fullListAreceber = [];
      this.listaPagar = [];
      this.listaVaziaMsg = "Nenhum resultado encontrado";
      this.estaCarregandoPremio = true;
      this.estaCarregandoPagamentoPremio = true;

      Premiacao.getListaPremiacoes(data)
        .then((response) => {
          this.listaAreceber = response.data.data;
          this.fullListAreceber = response.data.data;
          this.solicitaPagamentoPremio(response.data.data);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
            this.estaCarregandoPagamentoPremio = false;
          }
        })
        .finally(() => {
          this.estaCarregandoPremio = false;
        });
    },
    loadPayment(data = null) {
      Premiacao.getListPremiacoesPagas(data)
        .then((response) => {
          this.listaRecebido = response.data.data;
          this.fullListRecebido = response.data.data;
          this.listaVaziaMsg = "Nenhum resultado encontrado";
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
          }
        });
    },
    solicitaPagamentoPremio(array) {
      let lista = Object.values(array);
      lista.forEach((element) => {
        if (element.status_parcela == "RE" && element.status_premio == "A") {
          this.listaPagar.push({
            documento: element.documento,
            cliente: element.cliente,
            premiacao: element.vl_premiacao,
            check: true,
            id: element.id_premio,
          });
        }
      });

      this.estaCarregandoPagamentoPremio = false;
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      let dir = this.currentSortDir;
      this.currentSort = s;
      this.listaAreceber.sort(function (a, b) {
        let modifier = 1;

        if (dir === "desc") {
          modifier = -1;
        }

        if (s == "dt_emissao" || s == "dt_vencimento" || s == "previsao_pg") {
          if (Date.parse(a[s]) && Date.parse(b[s])) {
            let data1 = a[s].replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");
            let data2 = b[s].replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");

            if (data1 < data2) {
              return -1 * modifier;
            }

            if (data1 > data2) {
              return 1 * modifier;
            }
          }
        } else {
          if (a[s] < b[s]) {
            return -1 * modifier;
          }

          if (a[s] > b[s]) {
            return 1 * modifier;
          }
        }

        return 0;
      });
    },
    nextPage() {
      if (
        this.currentPageReceber * this.pageSizeReceber <
        this.listaAreceber.length
      ) {
        this.currentPageReceber++;
      }
      if (
        this.currentPageRecebido * this.pageSizeRecebido <
        this.listaRecebido.length
      ) {
        this.currentPageRecebido++;
      }
    },
    prevPage() {
      if (this.currentPageReceber > 1) {
        this.currentPageReceber--;
      }
      if (this.currentPageRecebido > 1) {
        this.currentPageRecebido--;
      }
    },
    sortPg(s) {
      if (s === this.currentSortPg) {
        this.currentSortDirPg =
          this.currentSortDirPg === "asc" ? "desc" : "asc";
      }
      let dir = this.currentSortDirPg;
      this.currentSortPg = s;
      this.listaRecebido.sort((a, b) => {
        let modifier = 1;

        if (dir === "desc") {
          modifier = -1;
        }

        if (a[s] < b[s]) {
          return -1 * modifier;
        }

        if (a[s] > b[s]) {
          return 1 * modifier;
        }

        return 0;
      });
    },
    solicitacaoPagamento() {
      if (this.nrNotaServico == "") {
        this.$helper.showMsg(
          "É preciso preencher o campo Nota fiscal de serviço",
          "warning"
        );
      } else {
        const fd = new FormData();
        fd.append("nfse", this.nrNotaServico);
        fd.append("parcelas", this.solicitaPg);
        fd.append("arquivoNome", this.arquivoNfse.name);
        fd.append("arquivo", this.arquivoNfse);

        Premiacao.saveSolicitaPagamento(fd)
          .then((response) => {
            if (response.status === 200) {
              this.loadPremium();
              this.abrirModal("modal-solicitacao-concluida");
              this.nrNotaServico = "";
              this.solicitaPg = [];
              this.$refs.files.value = null;
              this.nomeArquivo = "";
              this.seleckAll = false;
            }
          })
          .catch((e) => {
            if (
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            ) {
              this.searchResult = e.response.data.data;
            }
          });
      }
    },
    checkAll() {
      this.solicitaPg = [];

      if (!this.seleckAll) {
        for (let i in this.listaPagar) {
          this.solicitaPg.push(this.listaPagar[i].id);
        }
      }
      this.updateCheckall();
    },
    updateCheckall() {
      this.totalPremioParaPagar = 0;

      if (this.listaPagar.length == this.solicitaPg.length) {
        this.seleckAll = true;
      } else {
        this.seleckAll = false;
      }

      if (
        this.solicitaPg.length > 0 &&
        this.nrNotaServico != "" &&
        this.arquivoNfse != ""
      ) {
        this.habilita = false;
      } else {
        this.habilita = true;
      }

      this.listaPagar.forEach((element) => {
        for (let i = 0; i < this.solicitaPg.length; i++) {
          if (element.id == this.solicitaPg[i]) {
            this.totalPremioParaPagar += element.premiacao;
          }
        }
      });
    },
    arquivoNota(event) {
      this.arquivoNfse = event.target.files[0];
      this.nomeArquivo = this.arquivoNfse.name;

      if (
        this.solicitaPg.length > 0 &&
        this.nrNotaServico != "" &&
        this.arquivoNfse != ""
      ) {
        this.habilita = false;
      }
    },
    pesquisa() {
      if (this.pesquisaElemento != "") {
        let lista;
        let el = document.getElementById("receber");

        let icone = document.getElementById("icone-x");
        icone.style.display = "block";

        if (el.className.includes("active")) {
          lista = this.gettListaFiltrada(this.fullListAreceber);
          this.listaAreceber = lista;
        } else {
          lista = this.gettListaFiltrada(this.fullListaRecebido);
          this.listaRecebido = lista;
        }
      }
    },
    gettListaFiltrada(lista) {
      return lista.filter(this.montarFiltro);
    },
    montarFiltro(value) {
      let filtroTexto = this.pesquisaElemento.toUpperCase();

      let exibeRegistro =
        String(value.dt_emissao).toUpperCase().includes(filtroTexto) ||
        String(value.documento).toUpperCase().includes(filtroTexto) ||
        String(value.cliente).toUpperCase().includes(filtroTexto) ||
        String(value.dt_vencimento).toUpperCase().includes(filtroTexto) ||
        String(value.vl_parcela).toUpperCase().startsWith(filtroTexto) ||
        String(value.status_parcela).toUpperCase().includes(filtroTexto) ||
        String(value.premiacao).toUpperCase().startsWith(filtroTexto) ||
        String(value.previsao_pg).toUpperCase().includes(filtroTexto);
      return exibeRegistro;
    },
    limparFiltro() {
      location.reload();
    },
    buscarWhatsappFinanceiro() {
      Revenda.getDataService().then((response) => {
        this.whatsapp = response.data.data["whatsapp_financeiro"];
      });
    },
    abrirModal(idModal) {
      this.$bvModal.show(idModal);
    },
    fecharModal(idModal) {
      this.$bvModal.hide(idModal);
    },
  },
  computed: {
    sortedColumns() {
      let listaRecebe = this.listaAreceber;

      this.pageSizeReceber = this.quantidadePage;
      this.totalResultsReceber = Object.values(this.listaAreceber).length;

      if (this.totalResultsReceber > this.quantidadePage) {
        this.totalPagesReceber = Math.ceil(
          this.totalResultsReceber / this.quantidadePage
        );
        if (this.currentPageReceber == this.totalPagesReceber) {
          this.quantidadeResultsReceber = this.totalResultsReceber;
        } else {
          this.quantidadeResultsReceber =
            this.quantidadePage * this.currentPageReceber;
        }
      } else {
        this.quantidadeResultsReceber = this.totalResultsReceber;
        this.totalPagesReceber = 1;
      }

      let start = (this.currentPageReceber - 1) * this.pageSizeReceber;
      let end = this.currentPageReceber * this.pageSizeReceber;

      return listaRecebe.slice(start, end);
    },
    sortedColumnsPagas() {
      let listaRecebido = this.listaRecebido;

      this.pageSizeRecebido = this.quantidadePage;
      this.totalResultsRecebido = this.listaRecebido.length;

      if (this.totalResultsRecebido > this.quantidadePage) {
        this.totalPagesRecebido = Math.ceil(
          this.totalResultsRecebido / this.quantidadePage
        );
        if (this.currentPageRecebido == this.totalPagesRecebido) {
          this.quantidadeResultsRecebido = this.totalResultsRecebido;
        } else {
          this.quantidadeResultsRecebido =
            this.quantidadePage * this.currentPageRecebido;
        }
      } else {
        this.quantidadeResultsRecebido = this.totalResultsRecebido;
        this.totalPagesRecebido = 1;
      }

      let start = (this.currentPageRecebido - 1) * this.pageSizeRecebido;
      let end = this.currentPageRecebido * this.pageSizeRecebido;

      return listaRecebido.slice(start, end);
    },
    retornarLinkWhatsapp() {
      return () => {
        return this.$store.state.configPadrao.linkWhatsapp + this.whatsapp;
      };
    },
  },
};
</script>
